@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: light;
  src: url("../fonts/Roboto-Light.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.firebase-emulator-warning {
  display: none;
}

img {
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}
